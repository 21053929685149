import { AppBar, Tab, Tabs, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateInput,
  DateTimeInput,
  Edit,
  Link,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useEditController,
  useNotify,
  useQuery,
  useRefresh,
} from 'react-admin';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { customDataProvider } from '../data-provider';
import { UserReviews } from '../reviews/user-reviews';
import { UserTips } from '../tips/user-tips';
import {
  NoTitle,
  encodeJSONToBase64,
  getFormattedDate,
  getFormattedDate_T,
  getMembershipActionName,
  getMembershipTypeName,
  getPlanId,
  getUtcToLocalDateTime,
  isAdmin,
  isDayBirthday,
  isFoundingMember,
  isGss,
  isSuperAdmin,
  showToast,
} from '../utils';
import {
  FRANCHISE_PHASES,
  GUESTS,
  HEARD_ABOUT_US,
  MEMBERSHIP_CANCEL_REASON,
  MEMBERSHIP_STATUS,
  MEMBERSHIP_TYPES,
} from '../utils/constants';
import { UserCancelledAppointment } from './user-cancelled-appointments';
import { UserCompletedAppointment } from './user-completed-appointments';
import { UserTransactionsHistory } from './user-transactions-history';
import { UserUpcomingAppointment } from './user-upcoming-appointments';
import { UserTransactions } from './user-wallet-transactions';
import './user.css';
import styles from '../gift/gift.module.css';
import config from '../app.config';

const dangerBtn = {
  color: '#fff',
  backgroundColor: 'red',
};

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const validateAmount = [required()];
const validateDate = [required()];
const validateReason = [required()];
const validateOther = [required()];
const validateMembership = [required()];

export const UserEdit = ({ permissions, ...props }) => {
  const user_id = props.id;
  const [appointmentIndex, setAppointmentIndex] = React.useState(0);
  const [transactionsIndex, setTransactionsIndex] = React.useState(0);
  const [showModal, setShowModal] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showEditResumeDateModal, setShowEditResumeDateModal] = useState(false);
  const [reason, setReason] = useState('');
  const [transactionType, setTransactionType] = useState('appointment');
  const [_heardAbout, _setHeardAbout] = useState('');
  const refresh = useRefresh();
  const notify = useNotify();
  const { id } = useParams();
  const [showChangeHomeLocationModal, setShowChangeHomeLocationModal] =
    useState(false);
  const [showChangeMebershipTypeModal, setShowChangeMebershipTypeModal] =
    useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event, newValue) => {
    setAppointmentIndex(newValue);
  };

  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));

  const handleTransTabChange = (event, newValue) => {
    setTransactionsIndex(newValue);
  };
  const { data: locations } = useQuery({
    type: 'getList',
    resource: 'locations',
    payload: {},
  });

  const { data: membership } = useQuery({
    type: 'getOne',
    resource: 'membership',
    payload: {
      user_id,
    },
  });

  const controllerProps = useEditController({
    basePath: `/guest/${props.id}`,
    permissions: 'admin',
    resource: 'guest',
    id: props.id,
  });
  const user = controllerProps.record;

  const handleCancelMembership = form => {
    const otherReason = form?.cancelReason?.includes('Other');
    if (otherReason) {
      form?.cancelReason.push(form?.otherReason);
    }
    const reasons = form?.cancelReason?.filter(a => a !== 'Other');
    const payload = {
      user_id: Number(props?.id),
      action: 'cancel',
      cancel_reason: reasons?.join(', '),
    };
    customDataProvider
      .update('cancelMembership', {
        ...payload,
      })
      .then(
        () => {
          setShowCancelModal(false);
          notify('Membership cancelled!');
          refresh();
        },
        err => {
          notify(
            err.response ? err.response?.data?.message : err.message,
            'error',
          );
          refresh();
        },
      );
  };

  const handleEditResumeDateMembership = form => {
    customDataProvider
      .update('editMembershipResumeDate', {
        membership_id: membership?.id,
        resume_date: form?.resume_at,
      })
      .then(
        () => {
          setShowEditResumeDateModal(false);
          notify('Membership Resume Pause updated!');
          refresh();
        },
        err => {
          notify(
            err.response ? err.response?.data?.message : err.message,
            'error',
          );
          refresh();
        },
      );
  };
  const TransactionTypes = [
    { id: 'appointment', name: 'Appointments' },
    { id: 'membership', name: 'Membership' },
    { id: 'gift', name: 'Gift' },
    { id: 'tips', name: 'Tips' },
  ];

  const handleWalletUpdate = () => {
    setShowModal(true);
    refresh();
  };

  const showHomeLocation = () => {
    const location_id = user?.location_id
      ? user.location_id
      : membership.location_id;
    if (!locations) {
      return '';
    }
    const home_location = locations.find(loc => loc.id === location_id);

    return home_location && `${home_location.name}`;
  };

  const handleChangeHomeLocation = () => {
    setShowChangeHomeLocationModal(true);
  };

  const handleUpdateHomeLocation = form => {
    const payload = { location_id: form?.location, id: user?.id };
    customDataProvider.update('user', payload).then(
      () => {
        setShowChangeHomeLocationModal(false);
        notify('Home Location updated!');
        refresh();
      },
      err => {
        notify(
          err.response ? err.response?.data?.message : err.message,
          'error',
        );
      },
    );
  };

  const getLocationsOptions = () => {
    const optionsLocations = [];
    workLocations.map(loc => {
      return (
        loc.location.launch_status !== FRANCHISE_PHASES.PHASE3 &&
        optionsLocations.push({
          id: loc.location_id,
          label: `${loc.location.name}`,
          name: `${loc.location.name}`,
        })
      );
    });
    const filterLocations = _.orderBy(optionsLocations, ['state'], ['asc']);
    return filterLocations;
  };

  const ChangeLocationToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={handleUpdateHomeLocation} />
    </Toolbar>
  );

  const changeHomeLocationModal = () => {
    return (
      <Modal isOpen={showChangeHomeLocationModal}>
        <div className="modal-header">
          <div className="light-text"> Change Home Location </div>
          <CloseIcon
            onClick={() => setShowChangeHomeLocationModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">Choose Home Location</div>
        <SimpleForm
          // save={handleUpdateHomeLocation}
          toolbar={
            <ChangeLocationToolbar
              handleUpdateHomeLocation={handleUpdateHomeLocation}
            />
          }
          initialValues={{ location: membership?.location_id }}
        >
          <SelectInput source="location" choices={getLocationsOptions()} />
        </SimpleForm>
      </Modal>
    );
  };

  const handleMembershipTypeUpdate = formValues => {
    const { membership_type_id, stripe_plan_id } = membership;
    const { membershipTypes } = formValues;
    const isFounding = membership_type_id === 1 ? '' : 'founding';
    if (membershipTypes === stripe_plan_id + isFounding) {
      notify(
        `Already On '${getMembershipTypeName(membershipTypes)}' Membership`,
      );
      return;
    }

    const isFoundingOptionSelected = membershipTypes.includes('founding');
    const stripePlanSelected = membershipTypes.split('_')[0];
    const payload = {
      userId: user?.id,
      planId: membershipTypes.split('_')[0],
      action: getMembershipActionName(membershipTypes),
    };

    if (stripePlanSelected !== stripe_plan_id) {
      updateMembershipPlan(payload);
    }
    setTimeout(() => {
      if (!(isFoundingMember === isFoundingMember(membership))) {
        toggleFoundingMembership(
          {
            applyFoundingMembership: isFoundingOptionSelected,
            membershipId: membership?.id,
          },
          membershipTypes,
        );
      }
    }, 1000);
  };

  const toggleFoundingMembership = (payload, membershipTypes) => {
    customDataProvider.update('membershipFouding', payload).then(
      () => {
        setShowChangeMebershipTypeModal(false);
        showToast(
          `Updated to 
              ${getMembershipTypeName(membershipTypes)} Membership!`,
          'success',
        );
        refresh();
      },
      err => {
        setShowChangeMebershipTypeModal(false);
        notify(err.message, 'error');
      },
    );
  };

  const updateMembershipPlan = payload => {
    customDataProvider.update('membershipPlan', payload).then(
      () => {
        setShowChangeMebershipTypeModal(false);
        refresh();
      },
      err => {
        setShowChangeMebershipTypeModal(false);
        notify(err.message, 'error');
      },
    );
  };

  const EditMembershipToolbar = props => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-around' }}
    >
      <Button
        style={dangerBtn}
        variant="outlined"
        color="danger"
        onClick={() => setShowChangeMebershipTypeModal(false)}
      >
        Cancel
      </Button>
      <SaveButton label="Save" onSave={handleMembershipTypeUpdate} />
    </Toolbar>
  );

  const changeMebershipTypeModal = () => {
    return (
      <Modal isOpen={showChangeMebershipTypeModal}>
        <div className="modal-header">
          <div className="light-text">Edit Membership</div>
          <CloseIcon
            onClick={() => {
              setShowChangeMebershipTypeModal(false);
            }}
            className="close-button"
          />
        </div>
        <div className="text-center font-medium mt-3">
          Current Membership Type:{' '}
          {!isFoundingMember(membership) ? 'Regular' : 'Founding'} -{' '}
          {membership?.stripe_plan_id === '80minv2' ? 'Main' : 'Mid'}
        </div>
        <Edit {...props} className="simpleform-details-display">
          <SimpleForm toolbar={<EditMembershipToolbar />}>
            <SelectInput
              validate={validateMembership}
              source="membershipTypes"
              label="Change Membership Type"
              choices={MEMBERSHIP_TYPES}
            />
          </SimpleForm>
        </Edit>
      </Modal>
    );
  };

  const handleSave = form => {
    setIsSubmitting(true);
    const payload = {
      amount: form.amount,
      reason: form.reason,
      otherReason: form.otherReason,
    };
    customDataProvider.update('wallet', { id: props?.id, payload }).then(
      () => {
        setShowModal(false);
        notify('Wallet updated!');
        refresh();
        setIsSubmitting(false);
      },
      err => {
        notify(
          err.response ? err.response?.data?.message : err.message,
          'error',
        );
        refresh();
        setIsSubmitting(false);
      },
    );
  };

  const walletModal = () => {
    return (
      <Modal isOpen={showModal}>
        <div className="modal-header">
          <div className="light-text">Adjust Squeeze Bucks</div>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          Enter the amount you want to add or subtract (+/-) and the reason.
        </div>
        {isSubmitting ? (
          <div className={styles.loader}></div>
        ) : (
          <SimpleForm
            save={handleSave}
            initialValues={{ reason: 'Guest Satisfaction' }}
          >
            <TextInput source="amount" validate={validateAmount} />
            <SelectInput
              source="reason"
              onChange={e => setReason(e.target.value)}
              choices={[
                { id: 'Guest Satisfaction', name: 'Guest Satisfaction' },
                { id: 'Promotional Credit', name: 'Promotional Credit' },
                { id: 'Other', name: 'Other' },
              ]}
            />
            {reason === 'Other' && <TextInput source="otherReason" />}
          </SimpleForm>
        )}
      </Modal>
    );
  };

  const CancelMembershipToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="CANCEL MEMBERSHIP" onSave={handleCancelMembership} />
    </Toolbar>
  );

  const cancelModal = () => {
    return (
      <Modal isOpen={showCancelModal}>
        <div className="modal-header">
          <div className="light-text">Cancel Membership</div>
          <CloseIcon
            onClick={() => {
              setShowCancelModal(false);
              setCancelReason('');
            }}
            className="close-button"
          />
        </div>
        <div className="cancelModal">
          <SimpleForm
            // save={handleCancelMembership}
            toolbar={<CancelMembershipToolbar />}
            initialValues={{ cancelReason: '' }}
          >
            <p className="w100">
              Note, the guest’s membership will remain active until the end of
              the current membership term,{' '}
              {getFormattedDate(membership?.next_due_at)}. Please choose the
              reasons why the guest is choosing to cancel:
            </p>

            <AutocompleteArrayInput
              source="cancelReason"
              validate={validateReason}
              onChange={e => setCancelReason(e)}
              choices={MEMBERSHIP_CANCEL_REASON}
            />
            {cancelReason?.includes('Other') && (
              <TextInput
                label="Other Reason"
                source="otherReason"
                validate={validateOther}
              />
            )}
          </SimpleForm>
        </div>
      </Modal>
    );
  };
  const editResumeDateModal = () => {
    return (
      <Modal isOpen={showEditResumeDateModal}>
        <div className="modal-header">
          <div className="light-text">Resume Pause Date</div>
          <CloseIcon
            onClick={() => setShowEditResumeDateModal(false)}
            className="close-button"
          />
        </div>
        <div className="btn-padding">Select Date</div>
        <SimpleForm
          save={handleEditResumeDateMembership}
          initialValues={{ resume_at: membership?.resume_at }}
        >
          <DateTimeInput
            source="resume_at"
            defaultValue={membership?.resume_at}
            label="Start Date"
            validate={validateDate}
          />
        </SimpleForm>
      </Modal>
    );
  };

  const getMembershipStatus = membership => {
    if (_.isEmpty(membership)) {
      return 'non-member';
    } else if (membership?.cancelled_at || membership?.entity_status_id === 3) {
      return 'Cancelled';
    } else if (membership?.paused_at) {
      return 'Paused';
    } else if (membership?.campaign && membership?.campaign_id) {
      return `F${_.capitalize(membership?.name)} (${getPlanId(
        membership?.stripe_plan_id,
      )})`;
    } else {
      return `${_.capitalize(membership?.name)} (${getPlanId(
        membership?.stripe_plan_id,
      )})`;
    }
  };

  const handleUserUpdate = formValues => {
    const {
      first_name,
      last_name,
      phone,
      dob,
      guest_notes,
      vi_squeeze,
      heardAboutOther,
      heardAbout,
      yelp_reviewer,
      google_reviewer,
    } = formValues;
    let payload = {
      id: user_id,
      first_name,
      last_name: last_name || '',
      phone: phone || '',
      dob: dob || '',
      guest_notes: guest_notes || '',
      vi_squeeze: vi_squeeze ? true : false,
      yelp_reviewer: yelp_reviewer ? true : false,
      google_reviewer: google_reviewer ? true : false,
    };
    if (heardAbout)
      payload.heard_about_us =
        heardAbout === 'Other' ? heardAboutOther : heardAbout;
    customDataProvider.update('user', payload).then(
      () => {
        notify('User updated!');
      },
      err => {
        notify(
          err.response ? err.response?.data?.message : err.message,
          'error',
        );
        refresh();
      },
    );
  };

  const EditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={handleUserUpdate} />
    </Toolbar>
  );

  const iconWrap = icon => (
    <span className="icon-wrapper">
      <div class="fc-title-icon icon-fontsize w-10">{icon}</div>
    </span>
  );

  const preferenceBtn = {
    backgroundColor: '#2196F3',
    width: '6rem',
    borderRadius: '0.25rem',
    padding: '0.5rem',
  };

  const handleApptBooking = () => {
    if (user_id) {
      customDataProvider
        .create('createUserToken', {
          guestId: user_id,
        })
        .then(data => {
          if (data?.resp?.token) {
            const encodedData = encodeJSONToBase64(data?.resp);
            window.open(
              `${config.redirectUrl}?data=${encodedData}`,
              '_blank',
            );
          }
        });
    }
  };
  const renderIcons = () => {
    return (
      <div>
        {user && user.has_membership ? iconWrap('star_sharp') : ''}
        {user && user.minor ? iconWrap('escalator_warning') : ''}
        {user && user.vi_squeeze ? iconWrap('emoji_events_sharp') : ''}
        {user && user.pregnant ? iconWrap('local_hospital') : ''}
        {user && user.dob && isDayBirthday(user.dob) ? iconWrap('cake') : ''}
        {user && user.first_time_user ? iconWrap('fiber_new_sharp') : ''}
      </div>
    );
  };
  return (
    <>
      {changeMebershipTypeModal()}
      {walletModal()}
      {cancelModal()}
      {editResumeDateModal()}
      {changeHomeLocationModal()}
      <div className="details-display">
        <Edit
          resource={'guest'}
          basePath={`/guest`}
          id={id}
          initialValues={{
            heard_about_us: 'Select',
          }}
          title="Edit User"
          undoable={false}
          {...props}
        >
          <SimpleForm
            className="simpleform-details-display"
            toolbar={<EditToolbar />}
          >
            <TextField source="email" />
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="phone" />
            <DateInput source="dob" label="Date of Birth" />
            <SelectInput
              label="Heard about us?"
              source="heardAbout"
              choices={HEARD_ABOUT_US}
              initialValue={'Select'}
              onChange={e => _setHeardAbout(e.target.value)}
            />
            {(user?.heardAbout === 'Other' || _heardAbout === 'Other') && (
              <TextInput label="Other" source="heardAboutOther" multiline />
            )}
            <div className="flex w-0-imp">
              <BooleanInput
                label="V.I. Squeeze?"
                options={{
                  id: 'isViSqueeze',
                  // checked: test,
                }}
                source="vi_squeeze"
              />
              <BooleanInput
                label="Yelp Reviewer?"
                options={{
                  id: 'isYelpReviewer',
                  // checked: test,
                }}
                source="yelp_reviewer"
              />
              <BooleanInput
                label="Google Reviewer?"
                options={{
                  id: 'isGoogleReviewer',
                  // checked: test,
                }}
                source="google_reviewer"
              />
            </div>
          </SimpleForm>
        </Edit>
        <div className="flex-icons">
          <div className="big-icon-wrapper text-align-right flex-wrap">
            <div>{renderIcons()}</div>
            <div>
              <button
                className="button-blue"
                onClick={() => handleApptBooking()}
              >
                BOOK A MASSAGE
              </button>
            </div>
          </div>

          <Show title={<NoTitle />} {...props} actions="">
            <SimpleShowLayout className="right-panel">
              <div className="pt-2">
                <div className="label">Liability Waiver?</div>
                <div>{!!user?.liability_waiver ? 'Yes' : 'No'}</div>
                <div className="label">Last Visit</div>
                <div>
                  {user?.last_appointment_date
                    ? getFormattedDate_T(user?.last_appointment_date)
                    : ''}
                </div>
                <div className="label">
                  <div className="details-display">
                    <div>Membership?</div>
                    {(isSuperAdmin(permissions) ||
                      isAdmin(permissions) ||
                      isGss(permissions)) &&
                      !_.isEmpty(membership) &&
                      membership?.entity_status_id === 1 && (
                        <div
                          className="blueLink text-center"
                          onClick={() => setShowChangeMebershipTypeModal(true)}
                        >
                          Change
                        </div>
                      )}
                  </div>
                </div>
                <div>{getMembershipStatus(membership)}</div>
                {!(
                  _.isEmpty(membership) ||
                  membership?.cancelled_at ||
                  membership?.entity_status_id === 3
                ) && (
                  <>
                    <div className="label">
                      <div className="details-display">
                        <div>
                          {membership?.paused_at
                            ? 'Resume Pause Date'
                            : 'Membership Renewal Date'}
                        </div>
                        <div>
                          {membership?.paused_at && membership?.resume_at && (
                            <div
                              className="blueLink text-center"
                              onClick={() => setShowEditResumeDateModal(true)}
                            >
                              Edit
                            </div>
                          )}
                          {!_.isEmpty(membership) &&
                            membership?.status !== 'cancelled' &&
                            !membership?.cancelled_at && (
                              <div
                                className="blueLink text-center"
                                onClick={() => setShowCancelModal(true)}
                              >
                                Cancel
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {membership
                        ? getUtcToLocalDateTime(
                            membership?.paused_at
                              ? membership?.resume_at
                              : membership?.next_due_at,
                            // membership?.location?.timezone,
                            false,
                          )
                        : ''}
                    </div>
                  </>
                )}
                {membership?.location_id && (
                  <>
                    <div className="label">
                      <div className="details-display">
                        <div>Home Location</div>
                        {(isSuperAdmin(permissions) ||
                          isAdmin(permissions) ||
                          isGss(permissions)) &&
                          membership?.status !==
                            MEMBERSHIP_STATUS.isAwaiting && (
                            <div
                              className="blueLink text-center"
                              onClick={() => handleChangeHomeLocation()}
                            >
                              Change
                            </div>
                          )}
                      </div>
                    </div>
                    <div>{showHomeLocation()}</div>
                  </>
                )}
                <div className="label">
                  <div className="details-display">
                    <div>Squeeze Bucks</div>
                    <div
                      className="blueLink text-center"
                      onClick={() => handleWalletUpdate()}
                    >
                      +/-
                    </div>
                  </div>
                </div>
                <div>{`$${user?.balance ? user.balance : 0}`}</div>
                <div className="label">
                  <div>Preferences</div>
                </div>
                <Link
                  className="blueLink"
                  to={`/guest/preferences/${id}`}
                  query={user}
                  style={{ color: 'white' }}
                >
                  <button style={preferenceBtn}>View</button>
                </Link>
                <div className="flex guest-notes-main-wrap">
                  <Edit
                    resource={'guest'}
                    basePath={`/guest`}
                    id={id}
                    initialValues={{
                      heard_about_us: 'Select',
                    }}
                    title={' '}
                    undoable={false}
                    {...props}
                  >
                    <SimpleForm
                      toolbar={<EditToolbar />}
                      className="guest-notes-wrap"
                    >
                      <TextInput
                        label="Guest Notes"
                        source="guest_notes"
                        className="w-max guest-notes"
                        multiline
                      />
                    </SimpleForm>
                  </Edit>
                </div>
              </div>
            </SimpleShowLayout>
          </Show>
        </div>
      </div>
      <Show title={<NoTitle />} {...props} actions={<TopToolbar />}>
        <SimpleShowLayout>
          <AppBar color="transparent" position="static" className="biggerTabs">
            <Tabs
              value={appointmentIndex}
              onChange={handleChange}
              aria-label="Appointments Bar"
            >
              <Tab label="Upcoming Appointments" {...a11yProps(0)} />
              <Tab label="Completed Appointments" {...a11yProps(1)} />
              <Tab label="Cancelled Appointments" {...a11yProps(2)} />
            </Tabs>
          </AppBar>

          <TabPanel value={appointmentIndex} index={0}>
            <UserUpcomingAppointment card={GUESTS} {...props} />
          </TabPanel>
          <TabPanel value={appointmentIndex} index={1}>
            <UserCompletedAppointment card={GUESTS} {...props} />
          </TabPanel>
          <TabPanel value={appointmentIndex} index={2}>
            <UserCancelledAppointment card={GUESTS} {...props} />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
      <Show title={<NoTitle />} {...props} actions={<TopToolbar />}>
        <SimpleShowLayout>
          <AppBar color="transparent" position="static" className="biggerTabs">
            <Tabs value={0} aria-label="Reviews">
              <Tab label="Reviews" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <TabPanel value={0} index={0}>
            <UserReviews card={GUESTS} {...props} />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
      <Show title={<NoTitle />} {...props} actions={<TopToolbar />}>
        <SimpleShowLayout>
          <AppBar color="transparent" position="static" className="biggerTabs">
            <Tabs value={0} aria-label="Reviews">
              <Tab label="Tips" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <TabPanel value={0} index={0}>
            <UserTips card={GUESTS} {...props} />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
      <Show title={<NoTitle />} {...props} actions={<TopToolbar />}>
        <SimpleShowLayout>
          <AppBar color="transparent" position="static" className="biggerTabs">
            <Tabs
              value={transactionsIndex}
              onChange={handleTransTabChange}
              aria-label="Transactions Bar"
            >
              <Tab label="SB Change History" {...a11yProps(0)} />
              <Tab label="CC Charges" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={transactionsIndex} index={0}>
            <UserTransactions card={GUESTS} {...props} />
          </TabPanel>
          <TabPanel value={transactionsIndex} index={1}>
            <SimpleForm
              className="styled-form2"
              toolbar={<Toolbar style={{ display: 'none' }} />}
              initialValues={{
                transactionsId: 'appointment',
              }}
            >
              <div className="grid-3">
                <SelectInput
                  source="transactionsId"
                  choices={TransactionTypes}
                  label=""
                  onChange={e => setTransactionType(e.target.value)}
                />
              </div>
            </SimpleForm>
            <UserTransactionsHistory
              card={GUESTS}
              entity_type={transactionType}
              {...props}
            />
          </TabPanel>
        </SimpleShowLayout>
      </Show>
    </>
  );
};
